<template>
  <div>
    <CompanyInfo
      :images="images"
      :companyBrandColour="companyBrandColour"
      :companyLogo="companyLogo"
    >
      <template #companyName>Bayer</template>
      <template #date>1999 - Ongoing</template>
      <template #content>
        <ul class="dashed">
          <h4>Bayer AG</h4>
          <li>
            We supplied a moderator for a variety of interactive booth activities over the
            years.
          </li>
          <li>
            With this experience we were able to share our skills with exhibition booth
            staff by offering our booth team training sessions. Training sessions were
            tailor made to build team confidence, polish interaction skills and promote
            team support.
          </li>
          <li>
            During this time we moderated and directed a series of animated product
            information films that were then broadcast on Bayer's website. These adverts
            were created by an agency and we provided the voice and artistic direction.
          </li>
          <li>
            Most recently we have gone onto supplying our interactive iPad voting system
            at one of the largest medical meetings - the European Society Of Cardiology
            Congress (ESC).
          </li>
          <h4>Bayer Women's Health</h4>
          <li>
            We were delighted to deliver moderation supplying our interactive iPad voting
            system for Women’s Health Congresses educating and engaging with delegates in
            Canada and Europe.
          </li>
          <li>
            We supported a non promotional ‘Help’ booth where Bayer’s commitment to
            women’s health was their key messaging.
          </li>
          <li>
            Healthcare professionals were engaged throughout and after participating in
            our activity were given the opportunity to ask any questions where these were
            facilitated by Bayer’s Medical Representatives.
          </li>
          <li>
            Our team was also on site to support with Bayer’s Symposium sessions,
            promoting this through moderation, relaying key information and ultimately
            meeting and greeting delegates at the symposium halls.
          </li>
        </ul>
      </template>
    </CompanyInfo>
  </div>
</template>

<script>
import CompanyInfo from "@/components/Portfolio/CompanyInfo.vue";
export default {
  components: { CompanyInfo },
    name: 'BayerItem',
  data() {
    return {
      companyBrandColour: "#1A374E",
      companyLogo: require("@/assets/images/portfolio/bayer-logo.svg"),
      images: [
        {
          id: 0,
          responsiveImages: require("@/assets/images/portfolio/bayer/1.jpg"),
          position: { x: 50, y: 10 },
          backgroundImageTint: "rgba(0, 0, 0, 0.4)",
          pictureDetails: "EUROPEAN SOCIETY OF CARDIOLOGY (2018) MUNICH",
        },
        {
          id: 1,
          responsiveImages: require("@/assets/images/portfolio/bayer/2.jpg"),
          position: { x: 50, y: 25 },
          backgroundImageTint: "rgba(0, 0, 0, 0.4)",
          pictureDetails: "EUROPEAN STROKE ORGANISATION CONFERENCE (2019) MILAN",
        },
        {
          id: 2,
          responsiveImages: require("@/assets/images/portfolio/bayer/3.jpg"),
          position: { x: 50, y: 10 },
          backgroundImageTint: "rgba(0, 0, 0, 0.4)",
          pictureDetails: "EUROPEAN SOCIETY OF CARDIOLOGY (2019) PARIS",
        },
        {
          id: 3,
          responsiveImages: require("@/assets/images/portfolio/bayer/4.jpg"),
          position: { x: 50, y: 25 },
          backgroundImageTint: "rgba(100, 100, 100, 0.1)",
          pictureDetails: "WORLD CONGRESS OF CARDIOLOGY (2012) DUBAI",
        },
        {
          id: 4,
          responsiveImages: require("@/assets/images/portfolio/bayer/5.jpg"),
          position: { x: 50, y: 80 },
          backgroundImageTint: "rgba(100, 100, 100, 0.1)",
          pictureDetails: "WORLD CONGRESS OF CARDIOLOGY (2012) DUBAI",
        },
        {
          id: 5,
          responsiveImages: require("@/assets/images/portfolio/bayer/6.jpg"),
          position: { x: 50, y: 40 },
          backgroundImageTint: "rgba(100, 100, 100, 0.1)",
          pictureDetails: "WORLD CONGRESS OF CARDIOLOGY (2014) MELBOURNE",
        },
        {
          id: 6,
          responsiveImages: require("@/assets/images/portfolio/bayer/7.jpg"),
          position: { x: 50, y: 25 },
          backgroundImageTint: "rgba(100, 100, 100, 0.1)",
          pictureDetails:
            "SOCIETY OF ENDOMETRIOSIS AND UTERINE DISORDERS (2018) FLORENCE",
        },
        {
          id: 7,
          responsiveImages: require("@/assets/images/portfolio/bayer/8.jpg"),
          position: { x: 50, y: 25 },
          backgroundImageTint: "rgba(100, 100, 100, 0.1)",
          pictureDetails:
            "SOCIETY OF ENDOMETRIOSIS AND UTERINE DISORDERS (2018) FLORENCE",
        },
        {
          id: 8,
          responsiveImages: require("@/assets/images/portfolio/bayer/9.jpg"),
          position: { x: 50, y: 25 },
          backgroundImageTint: "rgba(100, 100, 100, 0.1)",
          pictureDetails:
            "SOCIETY OF ENDOMETRIOSIS AND UTERINE DISORDERS (2018) FLORENCE",
        },
        {
          id: 9,
          responsiveImages: require("@/assets/images/portfolio/bayer/10.jpg"),
          position: { x: 50, y: 25 },
          backgroundImageTint: "rgba(100, 100, 100, 0.1)",
          pictureDetails:
            "SOCIETY OF ENDOMETRIOSIS AND UTERINE DISORDERS (2018) FLORENCE",
        },
      ],
    };
  },
  // beforeMount() {
  //   this.loadImages(this.images)
  // }
};
</script>
<style lang="scss" scoped>
#companySummary > ul > h4:first-child {
  margin-top: 0px;
}
</style>
